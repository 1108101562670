<!--
 * @Author: your name
 * @Date: 2020-11-25 10:15:13
 * @LastEditTime: 2021-02-04 08:33:58
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/CertifiedPage/CardCertified.vue
-->step
<template>
    <div class="cardCertified">
        <div class="cardCertified-steps">
            <div class="step">
                <em class="completeEm"><van-icon name="success" /></em>
                <p class="complete">短信验证</p>
            </div>
            <div class="step">
                <em>2</em>
                <p>证件验证</p>
            </div>
            <div class="step">
                <em class="lastStepEm">3</em>
                <p class="lastStep">人脸识别</p>
            </div>
        </div>
        <div class="cardCertified-content">
            <div class="photo">
                <p>请上传身份证【正面】</p>
                <!-- <div class="cameraAndPhoto" @click="handleCard('face')" v-if="showFace">
                    <van-icon name="photograph" />
                </div> -->
                <!-- 上传后用这个 v-if="showFace" -->
                <van-field name="uploader">
                    <template #input>
                        <van-uploader v-model="uploader" :max-count="1"  :after-read="afterRead" @delete="deleteValue"/>
                    </template>
                </van-field>
            </div>
            <div class="photo">
                <p>请上传身份证【反面】</p>
                <!-- <div class="cameraAndPhoto" @click="handleCard('back')" v-if="showBack">
                    <van-icon name="photograph" />
                </div> -->
                <!-- 上传后用这个 -->
                <van-field name="uploaderBack">
                    <template #input>
                        <van-uploader v-model="uploaderBack" :max-count="1" :after-read="afterReadBack" @delete="deleteValue" />
                    </template>
                </van-field>
            </div>
            <div class="noticeInfo">
                <ul>
                    <li><van-icon name="success" class="colorGreen"/>四角完整</li>
                    <li><van-icon name="success" class="colorGreen"/>亮度均匀</li>
                    <li><van-icon name="success" class="colorGreen"/>照片清晰</li>
                    <li><van-icon name="cross" class="colorRed"/>照片缺边</li>
                    <li><van-icon name="cross" class="colorRed"/>照片模糊</li>
                    <li><van-icon name="cross" class="colorRed"/>打开闪光</li>
                </ul>
            </div>
        </div>

        <!-- 弹出框 -->
        <van-popup v-model="show" round closeable close-icon-position="top-left" position="bottom" :style="{ height: '30%' }">
            <div class="popupContent">
                <p>请选择</p>
                <div class="list">
                    <ul>
                        <li @click="getCamera">拍照
                            <!-- <input name="url" type="file" class="upload_input" @change="changeInput"  accept="image/*" style="visibility: hidden" capture="camera" multiple /> -->
                            <input class="camera" ref="camera" type="file" accept="image/*" capture="camera" @change="changeInput" multiple>
                        </li>
                        <li>
                            <van-uploader :max-count="1"  :after-read="afterRead">
                                从手机相册选择
                            </van-uploader>
                        </li>
                    </ul>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { uploadPictureBase64, ocrIdCard } from '../../api/home';

export default {
    name: 'CardCertified',
    data() {
        return {
            uploader: [],
            uploaderBack: [],
            imgId: '',
            show: false,
            pictureRealName: '',
            fileType: '',
            cardSide: 'face',
            showFace: true,
            showBack: true
        }
    },
    mounted() {
        // console.log('00000000', this.$route.query.phone)
    },
    methods: {
        // 此时可以自行将文件上传至服务器
        // beforeRead(file) {
        //     console.log('AA---',file)
        // },

        handleCard(kind) {
            this.show = true;
            this.cardSide = kind;
        },
        getCamera() {
            this.$refs.camera.click();
        },
        // 拍照
        changeInput() {
            let file = this.$refs.camera.files[0];
            // let reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onloadend = e => {
            //     let arg = {
            //         impStr: "01",
            //         pictureRealName: this.pictureRealName,
            //         fileType: this.fileType,
            //         cardSide: this.cardSide, // 如果是正面就是face 反面就是back
            //         imageString: e.target.result, // this.imageString,
            //         returnFlag: false
            //     }
            //     ocrIdCard(arg).then(res => {
            //         const {status, resultData} = res;
            //         if (status === 'complete') {
            //             if (resultData === 'fail') {
            //                 Toast.fail('上传失败');
            //             } else {
            //                 if (this.cardSide === 'face') {
            //                     this.showFace = false;
            //                     this.uploader = [{url: resultData}]
            //                 } else if (this.cardSide === 'back') {
            //                     this.showBack = false;
            //                     this.uploaderBack = [{url: resultData}]
            //                     this.$router.push({path: '/'})
            //                 }
            //                 Toast.success('上传成功');
            //             }
            //         } else {
            //             Toast.fail('上传失败');
            //         }
            //     })
            // }

        },
        afterRead(file) {
            this.pictureRealName = file.file.name.split('.')[0];
            this.fileType = file.file.name.split('.')[1];
            this.imageString = file.content;
            this.cardSide = 'face'
            this.ocrIdCard();
        },
        afterReadBack(file) {
            this.pictureRealName = file.file.name.split('.')[0];
            this.fileType = file.file.name.split('.')[1];
            this.imageString = file.content;
            this.cardSide = 'back'
            this.ocrIdCard();
        },
        // 上传
        async ocrIdCard() {
            let arg = {
                impStr: "01",
                pictureRealName: this.pictureRealName,
                fileType: this.fileType,
                imageString: this.imageString, // this.imageString,
                returnFlag: false,
                cardSide: this.cardSide, // 如果是正面就是face 反面就是back
                phone: this.$route.query && this.$route.query.phone
            }
            const {status, resultData} = await ocrIdCard(arg);
            if (status === 'complete') {
                this.show = false;
                if (resultData === 'fail') {
                    if (this.cardSide === 'face') {
                        Toast.fail('请上传身份证正面照');
                    }
                    if (this.cardSide === 'back') {
                        Toast.fail('请上传身份证反面照');
                    }
                } else {
                    if (this.cardSide === 'face') {
                        this.showFace = false;
                        this.uploader = [{url: resultData}]
                    } else if (this.cardSide === 'back') {
                        this.showBack = false;
                        this.uploaderBack = [{url: resultData}]
                        this.$router.push({path: '/'})
                    }
                    Toast.success('上传成功');
                }
            } else {
                if (this.cardSide === 'face') {
                    Toast.fail('请上传身份证正面照');
                } else if (this.cardSide === 'back') {
                    Toast.fail('请上传身份证反面照');
                }

            }
        },
        // 删除文件
        deleteValue() {
            if (this.cardSide === 'face') {
                this.showFace = true;
            } else if (this.cardSide === 'back') {
                this.showBack = true;
            }
        },
    }
}
</script>

<style lang="less" scoped>
.cardCertified {
    background: #ffffff;
    .cardCertified-steps {
        padding-top: 40px;
        padding-bottom: 30px;
        .step {
            display: inline-block;
            text-align: center;
            width: calc(100% / 3 );
            position: relative;
            em {
                width: 30px;
                height: 30px;
                line-height: 30px;
                background: #5F97F4;
                color: #ffffff;
                font-style: normal;
                display: inline-block;
                border-radius: 100px;
                text-align: center;
            }
            p {
                margin: 0px;
                padding-top: 10px;
                color: #5F97F4;
                font-size: 12px;

            }
            .completeEm {
                line-height: 35px;
                background: #0ACC9D;
            }
            .complete {
                color: #0ACC9D;
            }
            .lastStepEm {
                background: #cccccc;
            }
            .lastStep {
                color: #cccccc;
            }
        }
        .step::after {
            display: inline-block;
            content: '';
            width: 20px;
            height: 1px;
            background: #cccccc;
            position: absolute;
            right: 0px;
            top: 20%;
        }
        .step:last-child::after {
            display: inline-block;
            content: '';
            visibility: hidden;
        }
    }
    .cardCertified-content {
        .photo {
            padding-bottom: 20px;
            p {
                margin: 0px;
                text-align: center;
                color: #666666;
                font-size: 14px;
            }
            .cameraAndPhoto {
                background-color: #F5F5F5;
                margin: 0px 16px;
                text-align: center;
                margin-top: 10px;
                .van-icon {
                    color: #dcdee0;
                    line-height: 80px;
                    font-size: 20px;
                }
            }
            /deep/.van-uploader {
                margin: 0  auto;
            }
            /deep/.van-cell__value {
                background: rgba(246,246,246,0.50);
            }
            /deep/.van-uploader__upload {
                background: rgba(246,246,246,0.50);
            }
        }
        .noticeInfo {
            width: 100%;
            padding-bottom: 120px;
            padding-top: 20px;
            ul {
                margin: 0px 16px;
                li {
                    display: inline-block;
                    width: calc(100% / 3);
                    height: 30px;
                    line-height: 30px;
                    color: #666;
                    text-align: center;
                    .van-icon {
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                    .colorRed {
                        color: #F9464E;
                    }
                    .colorGreen {
                        color: #00C99D;
                    }
                }
            }
        }
    }
    .popupContent {
        p {
            text-align: center;
            color: #666;
        }
        .list {
            ul {
                text-align: center;
                margin: 0px 16px;
                li {
                    height: 35px;
                    line-height: 35px;
                    color: #666666;
                    font-size: 14px;
                    border-bottom: 1px solid #cccccc;
                }
                li:last-child {
                    border-bottom: 0px solid transparent;
                }
                .camera {
                    display: none;
                }
            }
        }
    }
}
</style>
